import ButtonMore from "components/ButtonMore";
import SectionTitle from "components/SectionTitle";
import FaqItems from "./FaqItems";

import st from "./Faq.module.scss";

const Faq = () => {
  return (
    <div className={st.faq_container}>
      <SectionTitle subTitle="FAQ" mainTitle="자주 묻는 질문" />
      <div className={st.faq_list}>
        <FaqItems />
      </div>
      <ButtonMore />
    </div>
  );
};

export default Faq;
