import React from "react";
import Faq from "components/dashboard/faq/Faq";
import Introduction from "components/dashboard/introduction/Introduction";
import MainFeatures from "components/dashboard/mainFeature/MainFeatures";
import ManagementSolution from "components/dashboard/managementSolution/ManagementSolution";
import News from "components/dashboard/news/News";
import ParticipatingCompanies from "components/dashboard/participatingCompanies/ParticipatingCompanies";
import Support from "components/dashboard/support/Support";
import System from "components/dashboard/system/System";

import st from "./Dashboard.module.scss";

const Dashboard = () => {
  return (
    <div className={st.dashboard}>
      <section id="introduction">
        <Introduction />
      </section>
      <section id="system">
        <System />
      </section>
      <section id="managementSolution">
        <ManagementSolution />
      </section>
      <section id="participatingCompanies">
        <ParticipatingCompanies />
      </section>
      <section id="mainFeatures">
        <MainFeatures />
      </section>
      <section id="news">
        <News />
      </section>
      <section id="faq">
        <Faq />
      </section>
      <section id="support">
        <Support />
      </section>
    </div>
  );
};

export default Dashboard;
