import SectionTitle from "components/SectionTitle";
import AutoPlay from "./AutoPlay";
import LogoMobileVersion from "./LogoMobileVersion";

import st from "./ParticipatingCompanies.module.scss";

const ParticipatingCompanies = () => {
  return (
    <div className={st.participating_companies_container}>
      <SectionTitle
        subTitle="Participating companies"
        mainTitle="myso는 공공기관 및 기업과 함께 합니다"
        detailInfo="myso는 블록체인 기반 소프트웨어 지적재산권(IP) 유통 및 관리 플랫폼입니다."
      />
      <AutoPlay />
      <LogoMobileVersion />
    </div>
  );
};

export default ParticipatingCompanies;
