import st from "./SlideItem.module.scss";

interface Props {
  title: string;
  content: string;
  style: {
    background: string;
  };
}

const SlideItem = ({ title, content, style }: Props) => {
  return (
    <div
      style={{ background: style.background }}
      className={st.slide_item_container}
    >
      <span>{title}</span>
      <p>{content}</p>
    </div>
  );
};

export default SlideItem;
