import { useState } from "react";
import Question from "./Question";

import st from "./FaqItems.module.scss";

const FaqItems = () => {
  const [isActive, setIsActive] = useState<number>();

  const handleClick = (index: number) => {
    setIsActive((prevActive) => (prevActive === index ? undefined : index));
  };

  return (
    <div>
      <Question
        question="이 사이트에서 제공하는 소프트웨어 저작물은 안전하게 보호되나요?"
        handleClick={() => handleClick(0)}
        index={0}
        isActive={isActive!}
      />
      {isActive === 0 && (
        <div className={st.answer}>
          이 사이트에서 제공하는 소프트웨어 저작물은 안전하게 보호되나요? 의
          대한 답변입니다. 이 사이트에서 제공하는 소프트웨어 저작물은 안전하게
          보호되나요? 의 대한 답변입니다. 이 사이트에서 제공하는 소프트웨어
          저작물은 안전하게 보호되나요? 의 대한 답변입니다.
        </div>
      )}

      <Question
        question="이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤 보장이 있나요?"
        handleClick={() => handleClick(1)}
        index={1}
        isActive={isActive!}
      />
      {isActive === 1 && (
        <div className={st.answer}>
          이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤
          보장이 있나요? 의 대한 답변입니다. 이 사이트를 통해 제공되는
          소프트웨어가 불법적으로 복제되지 않도록 어떤 보장이 있나요? 의 대한
          답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
        </div>
      )}

      <Question
        question="이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤 보장이 있나요?"
        handleClick={() => handleClick(2)}
        index={2}
        isActive={isActive!}
      />
      {isActive === 2 && (
        <div className={st.answer}>
          이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤
          보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
        </div>
      )}

      <Question
        question="이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤 보장이 있나요?"
        handleClick={() => handleClick(3)}
        index={3}
        isActive={isActive!}
      />
      {isActive === 3 && (
        <div className={st.answer}>
          이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤
          보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
        </div>
      )}

      <Question
        question="이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤 보장이 있나요?"
        handleClick={() => handleClick(4)}
        index={4}
        isActive={isActive!}
      />
      {isActive === 4 && (
        <div className={st.answer}>
          이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지 않도록 어떤
          보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
          <br />이 사이트를 통해 제공되는 소프트웨어가 불법적으로 복제되지
          않도록 어떤 보장이 있나요? 의 대한 답변입니다.
        </div>
      )}
    </div>
  );
};

export default FaqItems;
