import st from "./Card.module.scss";

interface Props {
  image: string;
  category: string;
  title: string;
  date: string;
}

const Card = ({ image, category, title, date }: Props) => {
  return (
    <div className={st.card_container}>
      <img className={st.image} src={image} alt="카드이미지" />
      <div className={st.card_content}>
        <span className={st.category}>{category}</span>
        <p className={st.title}>{title}</p>
        <span className={st.date}>{date}</span>
      </div>
    </div>
  );
};

export default Card;
