import Footer from "layout/Footer";
import Navbar from "layout/Navbar";
import { Outlet } from "react-router-dom";

import st from "./Root.module.scss";

const Root = () => {
  return (
    <section className={st.root}>
      <Navbar />
      <Outlet></Outlet>
      <Footer />
    </section>
  );
};

export default Root;
