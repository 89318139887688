import React, { useState, useRef } from "react";
import st from "./System.module.scss";
import distibutionBackground from "assets/image/swDistributionBackground.png";
import monitoringBackground from "assets/image/copyrightMonitoringBackground.png";
import trackingBackground from "assets/image/blockchainTrackingBackground.png";

const System = () => {
  return (
    <div className={st.system}>
      <div className={st.contents_container}>
        <div className={st.content}>
          <img src={distibutionBackground} />
          <div className={st.overlay}></div>
          <div className={st.text_container}>
            <span>S/W 유통관리 시스템</span>
            <p>
              Software Distribution and Management System (SDMS) simplifies
              software distribution, licensing, and oversight, ensuring
              efficiency and compliance
            </p>
          </div>
        </div>
        <div className={st.content}>
          <img src={monitoringBackground} />
          <div className={st.overlay}></div>
          <div className={st.text_container}>
            <span>저작권 모니터링 시스템</span>
            <p>
              Copyright Monitoring System (CMS) streamlines copyright
              protection, infringement detection, and compliance tracking,
              ensuring efficient enforcement and rights management.
            </p>
          </div>
        </div>
        <div className={st.content}>
          <img src={trackingBackground} />
          <div className={st.overlay}></div>
          <div className={st.text_container}>
            <span>블록체인기반 추적시스템</span>
            <p>
              Blockchain-based Tracking System (BTS) ensures secure, transparent
              tracking and verification, streamlining asset management,
              traceability, and data integrity across decentralized networks.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default System;
