import { ReactComponent as MysoLogoBigColored } from "assets/svg/mysoLogoBigColored.svg";
import { ReactComponent as NextArrowIcon } from "assets/svg/nextArrow.svg";
import { ReactComponent as PrevArrowIcon } from "assets/svg/prevArrow.svg";

import Slider from "react-slick";
import SlideItem from "./SlideItem";

import { useEffect, useState } from "react";
import st from "./MainFeatures.module.scss";
import "./Slide.scss";

interface ArrowProps {
  onClick?: () => void;
}

const NextArrow = (props: ArrowProps) => {
  const { onClick } = props;

  return <NextArrowIcon className={st.next_arrow} onClick={onClick} />;
};

const PrevArrow = (props: ArrowProps) => {
  const { onClick } = props;

  return <PrevArrowIcon className={st.prev_arrow} onClick={onClick} />;
};

const MainFeatures = () => {
  const [slidesToShow, setSlidesToShow] = useState(2.5);

  const handleResize = () => {
    if (window.innerWidth < 1080) {
      setSlidesToShow(1.5);
    } else if (window.innerWidth < 1790) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(2.5);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className={st.main_features_container}>
      <div className={st.section_title_container}>
        <MysoLogoBigColored className={st.logo_colored} />
        <div className={st.section_title}>
          <p>
            저작권 보호를 위한
            <br />
            <span>주요기능</span>
          </p>
        </div>
      </div>

      <Slider {...settings} className={st.slider_container}>
        <div>
          <SlideItem
            title="유통관리 모니터링"
            content="Software Distribution and Management System (SDMS) simplifies software distribution, licensing, and oversight, ensuring efficiency and compliance"
            style={{
              background: "linear-gradient(180deg, #585EFF 0%, #353899 100%)",
            }}
          />
        </div>
        <div>
          <SlideItem
            title="저작권 모니터링"
            content="The Copyright Monitoring System tracks and enforces copyright protections, detecting unauthorized use of copyrighted material for effective IP protection"
            style={{
              background: "linear-gradient(180deg, #0097FF 0%, #005B99 100%)",
            }}
          />
        </div>
        <div>
          <SlideItem
            title="블록체인기반 저작권 보호"
            content="Blockchain-based Tracking System allows efficient tracing and recording of product or asset origins, enhancing transaction transparency and security"
            style={{
              background: "linear-gradient(180deg, #B027FF 0%, #6A1799 100%)",
            }}
          />
        </div>
        <div>
          <SlideItem
            title="유통관리 모니터링"
            content="Software Distribution and Management System (SDMS) simplifies software distribution, licensing, and oversight, ensuring efficiency and compliance"
            style={{
              background: "linear-gradient(180deg, #585EFF 0%, #353899 100%)",
            }}
          />
        </div>
      </Slider>
    </div>
  );
};

export default MainFeatures;
