import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import st from "./Introduction.module.scss";
import ourCoreValuesIcon from "assets/image/ourCoreValuesIcon.png";
import contactUsIcon from "assets/image/contactUsIcon.png";
import bgImage1 from "assets/image/mysoIntroBackgroundVideo.gif";
import bgImage2 from "assets/image/mysoIntroBackgroundVideo2.gif";
import "./Introduction.scss";

const Introduction = () => {
  const [activeSlide, setActiveSlide] = useState<number>(0);
  const [updateCount, setUpdateCount] = useState(0);
  const sliderRef = useRef<Slider>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => setUpdateCount(updateCount + 1),
    beforeChange: (current: number, next: number) => setActiveSlide(next),
  };

  return (
    <div className={st.introduction}>
      <div className={st.slider_container}>
        <div className={st.slick_container}>
          <div className={st.numbers_container}>
            <span>01</span>
            <span>02</span>
          </div>
          <input
            onChange={(e) =>
              sliderRef.current?.slickGoTo(parseInt(e.target.value))
            }
            value={activeSlide}
            type="range"
            min={0}
            max={1}
          />
        </div>
        <Slider ref={sliderRef} {...settings} className={st.slider_container}>
          <div className={st.first_slide}>
            <div className={st.text_container}>
              <h2>
                블록체인 기반 ‘SW저작물’<br></br>유통 및 관리 플랫폼
              </h2>
              <p>
                myso는 블록체인 기반 소프트웨어 지적재산권(IP) 유통 및 관리
                플랫폼입니다.
              </p>
            </div>
          </div>
          <div className={st.second_slide}>
            <div className={st.text_container}>
              <h2>
                블록체인 기반 SW 저작권 보호<br></br>인재육성 사업 - 중앙대학교
              </h2>
              <p>
                myso는 블록체인 기반 소프트웨어 지적재산권(IP) 유통 및 관리
                플랫폼입니다.
              </p>
            </div>
          </div>
        </Slider>
      </div>
      <div className={st.bottom_container}>
        <div className={st.bottom_our_core_values}>
          <img src={ourCoreValuesIcon} />
          <div className={st.our_core_values_text_container}>
            <span className={st.our_core_values_text}>OUR CORE VALUES</span>
            <p className={st.our_core_values_subtext}>
              소프트웨어 저작권 관리 솔루션을 제공합니다.
            </p>
          </div>
        </div>
        <div className={st.bottom_contact_us}>
          <img src={contactUsIcon} />
          <div className={st.contact_us_text_container}>
            <span className={st.contact_us_text}>CONTACT US</span>
            <p className={st.contact_us_subtext}>
              온라인에서 창작의 가치를 지키는 곳, 저작권보호 지원센터와
              함께하세요.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;
