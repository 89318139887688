import Slider from "react-slick";

import cau from "assets/image/companyLogo-cau.png";
import fillTheFill from "assets/image/companyLogo-fillTheFill.png";
import kat from "assets/image/companyLogo-kat.png";
import kocca from "assets/image/companyLogo-kocca.png";
import kopa from "assets/image/companyLogo-kopa.png";
import spc from "assets/image/companyLogo-spc.png";

import st from "./AutoPlay.module.scss";

const AutoPlay = () => {
  const settingsRightToLeft = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 9000,
    autoplaySpeed: 9000,
    cssEase: "linear",
    arrows: false,
  };

  const settingsLeftToRight = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 9000,
    autoplaySpeed: 9000,
    cssEase: "linear",
    rtl: true,
    arrows: false,
  };

  return (
    <div className={st.sliders_container}>
      <Slider {...settingsRightToLeft} className={st.slider_container}>
        <div className={st.logo}>
          <img src={cau} alt="cau_logo" />
        </div>
        <div className={st.logo}>
          <img src={spc} alt="spc_logo" />
        </div>
        <div className={st.logo}>
          <img src={kocca} alt="kocca_logo" />
        </div>
        <div className={st.logo}>
          <img src={fillTheFill} alt="fillthefill_logo" />
        </div>
        <div className={st.logo}>
          <img src={kopa} alt="kopa_logo" />
        </div>
        <div className={st.logo}>
          <img src={kat} alt="kat_logo" />
        </div>
      </Slider>

      <Slider {...settingsLeftToRight} className={st.slider_container}>
        <div className={st.logo}>
          <img src={kocca} alt="kocca_logo" />
        </div>
        <div className={st.logo}>
          <img src={fillTheFill} alt="fillthefill_logo" />
        </div>
        <div className={st.logo}>
          <img src={kopa} alt="kopa_logo" />
        </div>
        <div className={st.logo}>
          <img src={kat} alt="kat_logo" />
        </div>
        <div className={st.logo}>
          <img src={cau} alt="cau_logo" />
        </div>
        <div className={st.logo}>
          <img src={spc} alt="spc_logo" />
        </div>
      </Slider>
    </div>
  );
};

export default AutoPlay;
