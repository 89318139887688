import { ReactComponent as Download } from "assets/svg/download.svg";
import { ReactComponent as FooterMysoLogo } from "assets/svg/footerMysoLogo.svg";

import st from "./Footer.module.scss";

const Footer = () => {
  return (
    <div className={st.footer_container}>
      <div className={st.logo_container}>
        <FooterMysoLogo />
        <button>
          회사소개서
          <span>
            <Download />
          </span>
        </button>
      </div>

      <div className={st.content_container}>
        <p>
          ㈜유니온콘텐츠 ㅣ 대표.서민승 ㅣ 211-88-85096 ㅣ
          <span>
            서울시 금천구 가산디지털2로 101, 한라원앤원타워 B동 1504호
          </span>
        </p>
        <p>biz@unionc.co.kr ㅣ https://unionc.co.kr</p>
        <p>모니터링 센터 운영시간 : 9:00 ~ 18:00 (점심시간: 11:30 ~ 13:00)</p>
        <p>T.070.7700.1555 ㅣ F.02.2176.9596</p>
        <p>© 2024 Unioncontents, Inc.</p>
      </div>
    </div>
  );
};

export default Footer;
