import cau from "assets/image/companyLogo-cau.png";
import fillTheFill from "assets/image/companyLogo-fillTheFill.png";
import kat from "assets/image/companyLogo-kat.png";
import kocca from "assets/image/companyLogo-kocca.png";
import kopa from "assets/image/companyLogo-kopa.png";
import spc from "assets/image/companyLogo-spc.png";

import st from "./LogoMobileVersion.module.scss";

const LogoMobileVersion = () => {
  return (
    <div className={st.logo_container}>
      <div className={st.logo}>
        <img src={kocca} alt="kocca_logo" />
      </div>
      <div className={st.logo}>
        <img src={fillTheFill} alt="fillthefill_logo" />
      </div>
      <div className={st.logo}>
        <img src={kopa} alt="kopa_logo" />
      </div>
      <div className={st.logo}>
        <img src={kat} alt="kat_logo" />
      </div>
      <div className={st.logo}>
        <img src={cau} alt="cau_logo" />
      </div>
      <div className={st.logo}>
        <img src={spc} alt="spc_logo" />
      </div>
    </div>
  );
};

export default LogoMobileVersion;
